
import { Vue, Component } from "vue-property-decorator";
import { AdminService } from "@/api/AdminServiceApi";
import { IBlogEntryUpdate } from "@/models/BlogEntry";
import Multiselect from "vue-multiselect";
import { IBlogEntryEdit } from "@/models/BlogEntryEdit";
import { TranslateResult } from "vue-i18n";
import { getS3BasePath } from "@/utility/StaticFileHelper";

@Component({
  components: { Multiselect }
})
export default class DashboardBlogEntriesEdit extends Vue {
  options: Array<IBlogEntryEdit> = [];
  searchQuery: string = "";
  blogId: number = 0;
  blogTitle: string = "";
  blogText: string = "";
  blogUrl: string = "";
  blogPictureUrl: string = "";
  metaTitle: string = "";
  metaDescription: string = "";
  metaDataId: number = 0;

  files: Array<File> = [];

  async updateBlogEntry() {
    let blogEntryUpdate: IBlogEntryUpdate = {
      id: this.blogId,
      title: this.blogTitle,
      text: this.blogText,
      metaTitle: this.metaTitle,
      metaDescription: this.metaDescription,
      metaDataId: this.metaDataId,
      file: { id: 0, fileName: "", type: "", base64: "" }
    };

    if (this.files.length > 0) {
      let file: File = this.files[0];
      const reader = new FileReader();
      reader.onload = async event => {
        if (event.target == null) {
          return;
        }

        blogEntryUpdate.file.fileName = file.name;
        blogEntryUpdate.file.type = file.type;
        blogEntryUpdate.file.base64 = event.target.result;
        await this.postUpdate(blogEntryUpdate);
      };

      reader.readAsDataURL(file);
    } else {
      await this.postUpdate(blogEntryUpdate);
    }
  }

  async deleteBlogEntry() {
    if (this.blogId <= 0) {
      return;
    }

    await AdminService.DeleteBlogEntry(this.blogId)
      .then(() => {
        this.$swal.fire({
          titleText: "Blogg borttagen",
          confirmButtonText: "Ok",
          icon: "success"
        });

        this.refresh();
      })
      .catch(() => {
        this.$swal.fire({
          titleText: "Någonting gick fel",
          confirmButtonText: "Ok",
          icon: "error"
        });
      });
  }

  async postUpdate(blogEntry: IBlogEntryUpdate) {
    await AdminService.UpdateBlogEntry(blogEntry)
      .then(() => {
        this.$swal.fire({
          titleText: "Blogg inlägget har uppdaterats",
          confirmButtonText: "Ok",
          icon: "success"
        });

        this.refresh();
      })
      .catch(() => {
        this.$swal.fire({
          titleText: "Någonting gick fel",
          confirmButtonText: "Ok",
          icon: "error"
        });
      });
  }

  get imagePreview() {
    if (this.files.length > 0) {
      return URL.createObjectURL(this.files[0]);
    }
  }

  addFileSelect(event: any) {
    if (event.target == null) {
      return;
    }

    const selectedFiles = event.target.files;
    if (!selectedFiles) {
      return;
    }

    this.files = [];
    [...selectedFiles].forEach(f => {
      this.files.push(f);
    });
  }

  addFileDrop(event: DragEvent): void {
    if (event.dataTransfer == null) {
      return;
    }

    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles) {
      return;
    }

    this.files = [];
    [...droppedFiles].forEach(f => {
      this.files.push(f);
    });
  }

  viewBlog(option: IBlogEntryEdit) {
    this.blogId = option.id;
    this.blogTitle = option.title;
    this.blogText = option.text;
    this.blogUrl = option.blogUrl;
    this.blogPictureUrl = option.pictureUrl;
    this.metaTitle = option.metaTitle;
    this.metaDescription = option.metaDescription;
    this.metaDataId = option.metaDataId;
  }

  async search(searchQuery: string) {
    if (searchQuery.length > 0) {
      await AdminService.SearchForBlog(searchQuery).then(response => {
        this.options = response.data;
      });
    }
  }

  customLabel(option: IBlogEntryEdit): TranslateResult {
    return `(ID: ${option.id}) - ${option.title}`;
  }

  refresh() {
    this.blogTitle = "";
    this.blogText = "";
    this.blogUrl = "";
    this.blogPictureUrl = "";
    this.metaTitle = "";
    this.metaDescription = "";
    this.metaDataId = 0;
    this.files = [];
    this.blogId = 0;
    this.options = [];
  }

  fullPicturePath(pictureUrl: string) {
    if (pictureUrl.length <= 0) {
      return;
    }

    return getS3BasePath() + pictureUrl;
  }
}
